import React from 'react';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useWindowDimensions } from '../components/AppBar';
var WHITESPACE = '\u00A0';
export var About = function () {
    var summary = (React.createElement(Stack, { spacing: 1 },
        React.createElement("div", null, "The Gumdrop program leverages the Solana blockchain and merkle trees to facilitate airdrops to a large number of whitelisted users at a low cost to creators."),
        React.createElement("div", null, "Various ecosystem projects want to ensure early followers and supporters gain access to project assets whether they be tokens, NFTs, or others. Simultaneously, capitalization of these assets should not incur undue costs or operational overhead to the creators. There are several ways to achieve such a setup and Gumdrop offers one that integrates with existing Solana and Metaplex ecosystem programs."),
        React.createElement("div", null, "Gumdrop solves this efficient-airdrop issue by utilizing a space-efficient hash structure (the merkle tree) such that an on-chain program can validate whether the user is part of a whitelist. This uses a pull-based paradigm to shift the burden from creators, sending airdrops or pre-minting NFTs, to recipients, that can choose to claim their portion or leave it for general adoption."),
        React.createElement("div", null,
            "The approach, originally pioneered for token airdrops by",
            ' ',
            React.createElement("a", { href: "https://github.com/Uniswap/merkle-distributor", target: "_blank", rel: "noreferrer" }, "Uniswap"),
            ' ',
            "and ported to Solana by",
            WHITESPACE,
            React.createElement("a", { href: "https://github.com/saber-hq/merkle-distributor", target: "_blank", rel: "noreferrer" }, "Saber"),
            ", is extended to allow pre-minting a Candy Machine or printing editions of a master copy. Moreover, Gumdrop allows creators to directly send whitelisted users a drop reclamation link by building the tree with off-chain handles (e.g email, discord, etc) and allowing the user to redeem into any wallet.")));
    var create = (React.createElement(Stack, { spacing: 1 },
        React.createElement("a", null, "CREATION"),
        React.createElement("div", null,
            "Creation builds a whitelist of users that can claim either existing fungible tokens or directly mint from a pre-sale Candy Machine. See a full explanation on the",
            ' ',
            React.createElement("a", { href: "https://docs.metaplex.com/airdrops/create-gumdrop", target: "_blank", rel: "noreferrer" }, "Metaplex Docs")),
        React.createElement("div", null,
            "Click",
            ' ',
            React.createElement("a", { href: "data:text/plain;charset=utf-8,".concat(JSON.stringify(require('./example.json'))), download: "example.json" }, "here"),
            ' ',
            "for an example distribution list with emails.")));
    var claim = (React.createElement(Stack, { spacing: 1 },
        React.createElement(Link, { to: "/claim" }, "CLAIMS"),
        React.createElement("div", null, "Claims are redeemed through a URL with query parameters holding claim-specific keys. Claimants will need to verify ownership of the specified handle by answering a OTP challenge and pay the rent and minting fees if applicable.")));
    var close = (React.createElement(Stack, { spacing: 1 },
        React.createElement("a", null, "CLOSING"),
        React.createElement("div", null, "Closing the Gumdrop cleans up the on-chain state and allows creators to recycle any lamports held for rent-exemption after the airdrop is complete."),
        React.createElement("div", null, "When closing a Candy Machine-integrated distributor, update authority will be transferred back to the wallet owner.")));
    var steps = [
        { name: 'summary', inner: summary },
        { name: 'create', inner: create },
        { name: 'claim', inner: claim },
        { name: 'close', inner: close },
    ];
    var maxWidth = 960;
    var width = useWindowDimensions().width;
    return (React.createElement(Stack, { alignContent: "left", textAlign: "left", spacing: 2, style: {
            margin: 'auto',
            maxWidth: Math.min(width, maxWidth),
        } }, steps.map(function (s, idx) { return (React.createElement("div", { key: idx }, s.inner)); })));
};
