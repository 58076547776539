import React from 'react';
import { Layout } from 'antd';
import { AppBar } from '../AppBar';
var Header = Layout.Header, Content = Layout.Content;
export var AppLayout = React.memo(function AppLayoutImpl(props) {
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { id: 'main-layout' },
            React.createElement("span", { id: 'main-bg' }),
            React.createElement("span", { id: 'bg-gradient' }),
            React.createElement("span", { id: 'static-header-gradient' }),
            React.createElement("span", { id: 'static-end-gradient' }),
            React.createElement(Layout, { id: 'width-layout' },
                React.createElement(Content, { style: {
                        padding: '30px 48px ',
                        flex: 'unset',
                    } },
                    React.createElement(Header, { className: "App-Bar" },
                        React.createElement(AppBar, null))),
                React.createElement(Content, { style: {
                        overflow: 'scroll',
                        paddingLeft: '96px',
                        paddingRight: '96px',
                        paddingBottom: '30px',
                        paddingTop: '-30px',
                    } }, props.children)))));
});
