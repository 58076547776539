var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Modal } from 'antd';
export var MetaplexModal = function (props) {
    var children = props.children, bodyStyle = props.bodyStyle, className = props.className, rest = __rest(props, ["children", "bodyStyle", "className"]);
    return (React.createElement(Modal, __assign({ bodyStyle: __assign({ background: '#2F2F2F', boxShadow: '0px 20px 12px 8px rgba(0, 0, 0, 0.3)', display: 'flex', flexDirection: 'column' }, bodyStyle), className: "modal-box small-modal ".concat(className), footer: null, width: 500 }, rest), children));
};
