var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useConnectionConfig } from '../contexts/ConnectionContext';
import { TokenListContainer } from '@solana/spl-token-registry';
import { WRAPPED_SOL_MINT } from '@project-serum/serum/lib/token-instructions';
// Tag in the spl-token-registry for sollet wrapped tokens.
export var SPL_REGISTRY_SOLLET_TAG = 'wrapped-sollet';
// Tag in the spl-token-registry for wormhole wrapped tokens.
export var SPL_REGISTRY_WORM_TAG = 'wormhole';
var TokenListContext = React.createContext(null);
export function SPLTokenListProvider(_a) {
    var _b = _a.children, children = _b === void 0 ? null : _b;
    var _c = __read(useState(null), 2), tokenList = _c[0], setTokenList = _c[1];
    var subscribedTokenMints = process.env.NEXT_SPL_TOKEN_MINTS
        ? __spreadArray([WRAPPED_SOL_MINT], __read(process.env.NEXT_SPL_TOKEN_MINTS.split(',')), false) : [WRAPPED_SOL_MINT];
    var tokens = useConnectionConfig().tokens;
    useEffect(function () {
        setTokenList(new TokenListContainer(Array.from(tokens.values())));
    }, [setTokenList, tokens]);
    var hasOtherTokens = !!process.env.NEXT_SPL_TOKEN_MINTS;
    // Added tokenList to know in which currency the auction is (SOL or other SPL)
    var subscribedTokens = tokenList
        ? tokenList
            .getList()
            .filter(function (f) { return subscribedTokenMints.some(function (s) { return s == f.address; }); })
        : [];
    var tokenMap = useMemo(function () {
        var tokenMap = new Map();
        subscribedTokens.forEach(function (t) {
            tokenMap.set(t.address, t);
        });
        return tokenMap;
    }, [tokenList]);
    // Tokens with USD(x) quoted markets.
    var swappableTokens = useMemo(function () {
        var tokens = subscribedTokens.filter(function (t) {
            var _a, _b;
            var isUsdxQuoted = ((_a = t.extensions) === null || _a === void 0 ? void 0 : _a.serumV3Usdt) || ((_b = t.extensions) === null || _b === void 0 ? void 0 : _b.serumV3Usdc);
            return isUsdxQuoted;
        });
        tokens.sort(function (a, b) {
            return a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0;
        });
        return tokens;
    }, [tokenList, tokenMap]);
    // Sollet wrapped tokens.
    var _d = __read(useMemo(function () {
        var tokens = subscribedTokens.filter(function (t) {
            var _a;
            var isSollet = (_a = t.tags) === null || _a === void 0 ? void 0 : _a.includes(SPL_REGISTRY_SOLLET_TAG);
            return isSollet;
        });
        tokens.sort(function (a, b) {
            return a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0;
        });
        return [
            tokens,
            new Map(tokens.map(function (t) { return [t.address, t]; })),
        ];
    }, [tokenList]), 2), swappableTokensSollet = _d[0], solletMap = _d[1];
    // Wormhole wrapped tokens.
    var _e = __read(useMemo(function () {
        var tokens = subscribedTokens.filter(function (t) {
            var _a;
            var isSollet = (_a = t.tags) === null || _a === void 0 ? void 0 : _a.includes(SPL_REGISTRY_WORM_TAG);
            return isSollet;
        });
        tokens.sort(function (a, b) {
            return a.symbol < b.symbol ? -1 : a.symbol > b.symbol ? 1 : 0;
        });
        return [
            tokens,
            new Map(tokens.map(function (t) { return [t.address, t]; })),
        ];
    }, [tokenList]), 2), swappableTokensWormhole = _e[0], wormholeMap = _e[1];
    return (React.createElement(TokenListContext.Provider, { value: {
            subscribedTokens: subscribedTokens,
            tokenMap: tokenMap,
            wormholeMap: wormholeMap,
            solletMap: solletMap,
            swappableTokens: swappableTokens,
            swappableTokensWormhole: swappableTokensWormhole,
            swappableTokensSollet: swappableTokensSollet,
            hasOtherTokens: hasOtherTokens,
        } }, children));
}
export var useTokenMap = function () {
    var tokenMap = useTokenList().tokenMap;
    return tokenMap;
};
export var useSwappableTokens = function () {
    var _a = useTokenList(), swappableTokens = _a.swappableTokens, swappableTokensWormhole = _a.swappableTokensWormhole, swappableTokensSollet = _a.swappableTokensSollet;
    return { swappableTokens: swappableTokens, swappableTokensWormhole: swappableTokensWormhole, swappableTokensSollet: swappableTokensSollet };
};
export var queryTokenList = function () {
    var subscribedTokens = useTokenList().subscribedTokens;
    return subscribedTokens;
};
export var useTokenList = function () {
    var context = useContext(TokenListContext);
    return context;
};
