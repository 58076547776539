var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
export var LoadingContext = React.createContext(null);
export var LoaderProvider = function (_a) {
    var children = _a.children;
    var _b = __read(React.useState(0), 2), loading = _b[0], setLoading = _b[1];
    return (React.createElement(LoadingContext.Provider, { value: {
            loading: loading,
            setLoading: setLoading,
        } },
        React.createElement("div", { className: "loader-container ".concat(loading ? 'active' : '') },
            React.createElement("div", { className: "loader-block" },
                React.createElement("div", { className: "loader-title" }, "loading"),
                React.createElement(Spinner, null))),
        children));
};
export var incLoading = function (p) { return p + 1; };
export var decLoading = function (p) { return p - 1; };
export var useLoading = function () {
    var context = React.useContext(LoadingContext);
    if (context === null) {
        throw new Error("useLoading must be used with a LoadingProvider");
    }
    return context;
};
export var Spinner = function () {
    return (React.createElement("div", { className: "spinner" },
        React.createElement("span", { className: "line line-1" }),
        React.createElement("span", { className: "line line-2" }),
        React.createElement("span", { className: "line line-3" }),
        React.createElement("span", { className: "line line-4" }),
        React.createElement("span", { className: "line line-5" }),
        React.createElement("span", { className: "line line-6" }),
        React.createElement("span", { className: "line line-7" }),
        React.createElement("span", { className: "line line-8" }),
        React.createElement("span", { className: "line line-9" })));
};
