var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useRef } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { Button, Popover, Select, Tooltip } from 'antd';
import Jazzicon from 'jazzicon';
import { CopyOutlined } from '@ant-design/icons';
import bs58 from 'bs58';
import cogSvg from './cog.svg';
import { ENDPOINTS, useConnectionConfig, } from '../../contexts/ConnectionContext';
import { useWalletModal } from '../../contexts/WalletContext';
import { useSolPrice } from '../../contexts/coingecko';
import { useNativeAccount } from '../../contexts/accounts';
import { formatNumber, formatUSD, shortenAddress } from '../../utils/common';
import { useQuerySearch } from '../../hooks/useQuerySearch';
export var Identicon = function (props) {
    var _a;
    var style = props.style, className = props.className, alt = props.alt;
    var address = typeof props.address === 'string'
        ? props.address
        : (_a = props.address) === null || _a === void 0 ? void 0 : _a.toBase58();
    var ref = useRef();
    useEffect(function () {
        if (address && ref.current) {
            try {
                ref.current.innerHTML = '';
                ref.current.className = className || '';
                ref.current.appendChild(Jazzicon((style === null || style === void 0 ? void 0 : style.width) || 16, parseInt(bs58.decode(address).toString('hex').slice(5, 15), 16)));
            }
            catch (err) {
                // TODO
            }
        }
    }, [address, style, className]);
    return (React.createElement("div", { className: "identicon-wrapper", title: alt, ref: ref, style: props.style }));
};
export var Settings = function (_a) {
    var additionalSettings = _a.additionalSettings;
    var publicKey = useWallet().publicKey;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                paddingTop: '15px',
            } },
            React.createElement(Identicon, { address: publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58(), style: {
                    width: 48,
                } }),
            publicKey && (React.createElement(React.Fragment, null,
                React.createElement(Tooltip, { title: "Copy address" },
                    React.createElement("div", { style: {
                            fontWeight: 600,
                            letterSpacing: '-0.02em',
                            color: '#FFFFFF',
                        }, onClick: function () {
                            return navigator.clipboard.writeText((publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58()) || '');
                        } },
                        React.createElement(CopyOutlined, null),
                        "\u00A0",
                        shortenAddress(publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58()))))),
            React.createElement("br", null),
            React.createElement("span", { style: {
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    width: 'calc(100% + 32px)',
                    marginTop: 10,
                    marginBottom: 10,
                } }),
            additionalSettings)));
};
var btnStyle = {
    border: 'none',
    height: 40,
};
export var CurrentUserBadge = function (props) {
    var _a = useWallet(), wallet = _a.wallet, publicKey = _a.publicKey, disconnect = _a.disconnect;
    var account = useNativeAccount().account;
    var solPrice = useSolPrice();
    if (!wallet || !publicKey) {
        return null;
    }
    var balance = ((account === null || account === void 0 ? void 0 : account.lamports) || 0) / LAMPORTS_PER_SOL;
    var balanceInUSD = balance * solPrice;
    var iconStyle = {
        display: 'flex',
        width: props.iconSize,
        borderRadius: 50,
    };
    var name = props.showAddress ? shortenAddress("".concat(publicKey)) : '';
    var unknownWallet = wallet;
    if (unknownWallet.name && !props.showAddress) {
        name = unknownWallet.name;
    }
    var image = React.createElement(Identicon, { address: publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58(), style: iconStyle });
    if (unknownWallet.image) {
        image = React.createElement("img", { src: unknownWallet.image, style: iconStyle });
    }
    return (React.createElement("div", { className: "wallet-wrapper" },
        props.showBalance && (React.createElement("span", null,
            formatNumber.format(((account === null || account === void 0 ? void 0 : account.lamports) || 0) / LAMPORTS_PER_SOL),
            " SOL")),
        React.createElement(Popover, { trigger: "click", placement: "bottomRight", content: React.createElement(Settings, { additionalSettings: React.createElement("div", { style: {
                        width: 250,
                        paddingLeft: '10px',
                    } },
                    React.createElement("h5", { style: {
                            color: 'rgba(255, 255, 255, 0.7)',
                            letterSpacing: '0.02em',
                        } }, "BALANCE"),
                    React.createElement("div", { style: {
                            marginBottom: 10,
                        } },
                        React.createElement("span", { style: {
                                fontWeight: 600,
                                color: '#FFFFFF',
                            } },
                            formatNumber.format(balance),
                            " SOL"),
                        "\u00A0",
                        React.createElement("span", { style: {
                                color: 'rgba(255, 255, 255, 0.5)',
                            } }, formatUSD.format(balanceInUSD)),
                        "\u00A0"),
                    React.createElement("div", { style: {
                            display: 'flex',
                            marginBottom: 10,
                        } },
                        React.createElement(Button, { className: "metaplex-button-default", onClick: disconnect, style: __assign(__assign({}, btnStyle), { paddingLeft: 0 }) }, "Disconnect"))) }) },
            React.createElement(Button, { className: "wallet-key" },
                image,
                name && (React.createElement("span", { style: {
                        marginLeft: '0.5rem',
                        fontWeight: 600,
                    } }, name))))));
};
export var Cog = function () {
    var endpoint = useConnectionConfig().endpoint;
    var routerSearchParams = useQuerySearch();
    var setVisible = useWalletModal().setVisible;
    var open = useCallback(function () { return setVisible(true); }, [setVisible]);
    return (React.createElement("div", { className: "wallet-wrapper" },
        React.createElement(Popover, { trigger: "click", placement: "bottomRight", content: React.createElement("div", { style: {
                    width: 250,
                } },
                React.createElement("h5", { style: {
                        color: 'rgba(255, 255, 255, 0.7)',
                        letterSpacing: '0.02em',
                    } }, "NETWORK"),
                React.createElement(Select, { onSelect: function (network) {
                        // Reload the page, forward user selection to the URL querystring.
                        // The app will be re-initialized with the correct network
                        // (which will also be saved to local storage for future visits)
                        // for all its lifecycle.
                        routerSearchParams.set('network', network);
                        console.log("Set window search to ".concat(network, " ").concat(routerSearchParams.toString(), ": ").concat(window.location.search));
                        window.location.search = "?".concat(routerSearchParams.toString());
                    }, value: endpoint.name, bordered: false, style: {
                        background: 'rgba(255, 255, 255, 0.05)',
                        borderRadius: 8,
                        width: '100%',
                        marginBottom: 10,
                    } }, ENDPOINTS.map(function (_a) {
                    var name = _a.name;
                    return (React.createElement(Select.Option, { value: name, key: name }, name));
                })),
                React.createElement(Button, { className: "metaplex-button-default", style: btnStyle, onClick: open }, "Change wallet")) },
            React.createElement(Button, { className: "wallet-key" },
                React.createElement("img", { src: cogSvg })))));
};
export var CurrentUserBadgeMobile = function (props) {
    var _a = useWallet(), wallet = _a.wallet, publicKey = _a.publicKey, disconnect = _a.disconnect;
    var account = useNativeAccount().account;
    var solPrice = useSolPrice();
    if (!wallet || !publicKey) {
        return null;
    }
    var balance = ((account === null || account === void 0 ? void 0 : account.lamports) || 0) / LAMPORTS_PER_SOL;
    var balanceInUSD = balance * solPrice;
    var iconStyle = {
        display: 'flex',
        width: props.iconSize,
        borderRadius: 50,
    };
    var name = props.showAddress ? shortenAddress("".concat(publicKey)) : '';
    var unknownWallet = wallet;
    if (unknownWallet.name && !props.showAddress) {
        name = unknownWallet.name;
    }
    var image = React.createElement(Identicon, { address: publicKey === null || publicKey === void 0 ? void 0 : publicKey.toBase58(), style: iconStyle });
    if (unknownWallet.image) {
        image = React.createElement("img", { src: unknownWallet.image, style: iconStyle });
    }
    return (React.createElement("div", { className: "current-user-mobile-badge" },
        React.createElement("div", { className: "mobile-badge" },
            image,
            name && (React.createElement("span", { style: {
                    marginLeft: '0.5rem',
                    fontWeight: 600,
                } }, name))),
        React.createElement("div", { className: "balance-container" },
            React.createElement("span", { className: "balance-title" }, "Balance"),
            React.createElement("span", null,
                formatNumber.format(balance),
                "\u00A0\u00A0 SOL",
                ' ',
                React.createElement("span", { style: {
                        marginLeft: 5,
                        fontWeight: 'normal',
                        color: 'rgba(255, 255, 255, 0.5)',
                    } }, formatUSD.format(balanceInUSD)))),
        React.createElement("div", { className: "actions-buttons" },
            React.createElement(Button, { className: "black-btn", onClick: disconnect }, "Disconnect"))));
};
